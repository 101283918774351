@import "../../../theme/variables";
/* Position and sizing of burger button */

.bm-burger-button {
  position: absolute;
  width: 29px;
  height: 24px;
  left: 36px;
  top: 24px;
  display: none;
}
.navbar {
  box-shadow: none;
  height: 64px;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  //background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  top: 64px;
  .expand-btn {
    display: none !important;
    position: absolute;
    right: 27px;
    top: 9px;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: 24px;
    background: transparent;
    cursor: pointer;
    color: #ababab;
  }

  &.minified {
    transform: translate3d(-75%, 0px, 0px) !important;
    .bm-menu {
      padding-right: 9px;
      .bm-item-list {
        padding-right: 0px !important;
      }
      .bm-item {
        justify-content: flex-end !important;
        border-bottom: 0;
        .nav-item-text {
          display: none;
        }
      }
    }
    .bm-cross-button {
      display: none;
    }
    .expand-btn {
      display: block !important;
    }
  }
}

/* General sidebar styles */
.bm-menu {
  background: #f2f4f0;
  padding: 2.5em 1em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: $color-primary;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  &:focus {
    outline: none;
  }
  &.nav-link {
    display: inline-block;
    color: $color-primary;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0;
    border-bottom: 1px solid rgba(4, 12, 165, 0.17);
    &:focus {
      outline: none;
    }
    i {
      font-size: 24px;
      &.ic-arrow {
        display: none;
      }
    }
    &.active {
      color: #a5a5a5;
    }
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .wrapper .navbar {
    height: 64px !important;
  }
  .bm-burger-button {
    display: block;
    // height: ;
    position: absolute;
    width: 21px;
    height: 18px;
    left: 23px;
    top: 22px;
  }
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
    top: 0px;
  }
  .bm-menu {
    background: $color-primary;
    padding: 2.5em 1em 0;
    font-size: 1.15em;
  }
  .bm-item {
    &:focus {
      outline: none;
    }
    &.nav-link {
      display: inline-block;
      color: white;
      font-size: 16px;
      font-weight: 700;
      padding: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.38);
      &:focus {
        outline: none;
      }
      i {
        font-size: 24px;
      }
    }
  }
}
