.user-menu {
  z-index: 999;
  position: absolute;
  right: 0px;
  top: 48px;
  width: 230px;

  .list-group-item {
    padding: 0;
    a,
    span {
      text-decoration: none;
      width: 100%;
      color: black;
      padding: 10px;
      display: block;
      cursor: pointer;
    }
  }
}
:focus {
  outline: none !important;
}
